"use strict";

var scrollAnimate = require("base/components/scrollAnimate");

function getProductSchema() {
    try {
        const $productSchema = $("#js-product-schema");
        if ($productSchema.length > 0) {
            return JSON.parse($productSchema.val());
        }
        throw new Error("");
    } catch (error) {
        return {};
    }
}

module.exports = {
    init: function () {
        var prDataSelectors = document.querySelectorAll("div.power-reviews .power-reviews-data");
        var renderArray = [];
        prDataSelectors.forEach((prDataSelector) => {
            const prApiKey = prDataSelector.dataset.apiKey;

            if (prApiKey) {
                const prLocale = prDataSelector.dataset.locale;
                const prMerchantGroupId = prDataSelector.dataset.merchantGroupId;
                const prMerchantId = prDataSelector.dataset.merchantId;
                const prWriteWrapperURL = prDataSelector.dataset.writeWrapperUrl;
                const prFeedlessData = prDataSelector.dataset.feedlessData;
                const productOrMasterId = prDataSelector.dataset.productOrMasterId;
                var components = {};

                var reviewSnippetSelector =  "pr-reviewsnippet-" + productOrMasterId;
                if (document.querySelector("#"+reviewSnippetSelector)) {
                    components.ReviewSnippet = reviewSnippetSelector;
                }

                var reviewDisplaySelector = "pr-reviewdisplay-" + productOrMasterId;
                if (document.querySelector("#"+reviewDisplaySelector)) {
                    components.ReviewDisplay = reviewDisplaySelector;
                }

                var questionSnippetSelector = "pr-questionsnippet-" + productOrMasterId;
                if (document.querySelector("#"+questionSnippetSelector)) {
                    components.QuestionSnippet = questionSnippetSelector;
                }

                var questionDisplaySelector = "pr-questiondisplay-" + productOrMasterId;
                if (document.querySelector("#"+questionDisplaySelector)) {
                    components.QuestionDisplay = questionDisplaySelector;
                }

                renderArray.push({ //eslint-disable-line no-undef
                    api_key: prApiKey,
                    locale: prLocale,
                    merchant_group_id: prMerchantGroupId,
                    merchant_id: prMerchantId,
                    page_id: productOrMasterId,
                    review_wrapper_url: prWriteWrapperURL,
                    product: JSON.parse(prFeedlessData),
                    ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
                    components: components,
                    on_change: function(config, data) {
                        if (config.component === "ReviewList") {
                            if (data.review_count > 0 && (data.reviews).length > 0) {
                                var reviews = [];
                                for (var i = 0; i <= ((data.reviews).length)-1; i++) {
                                    var pwrReview = data.reviews[i];
                                    var review = {
                                        "@type": "Review",
                                        "name": pwrReview.details.headline,
                                        "reviewBody": pwrReview.details.comments,
                                        "datePublished": new Date(pwrReview.details.created_date).toISOString(),
                                        "author": {
                                            "@type": "Person",
                                            "name": pwrReview.details.nickname
                                        },
                                        "reviewRating": {
                                            "@type": "Rating",
                                            "ratingValue": pwrReview.metrics.rating
                                        }
                                    };

                                    if (pwrReview.details.location && (pwrReview.details.location).toLowerCase() != "undisclosed") {
                                        review["locationCreated"] = {
                                            "@type": "AdministrativeArea",
                                            "name": pwrReview.details.location
                                        };
                                    }

                                    reviews.push(review);
                                }

                                var scriptTag = document.getElementById("product-schema");
                                var scriptTagObject = JSON.parse(scriptTag.innerHTML);
                                scriptTagObject["review"] = reviews;
                                scriptTag.innerHTML = JSON.stringify(scriptTagObject);                
                            }
                        }
                    },
                    on_render: function (config, data) {
                        if (config.component == "ReviewSnippet") {
                            var productReviewSnippetSelector = "div#pr-reviewsnippet-" + productOrMasterId + "[data-pr-component=ReviewSnippet]";
                            var $revSnippet = $(document).find(productReviewSnippetSelector);
                            $revSnippet.find(".pr-snippet-write-review-link").text($revSnippet.attr("data-write-text"));
                            $revSnippet.find(".pr-snippet-review-count").text(data.review_count);

                            var aggregateRating = {};
                            aggregateRating["@context"] = "http://schema.org/";
                            aggregateRating["@type"] = "AggregateRating";
                            aggregateRating["ratingValue"] = data.average_rating;
                            aggregateRating["reviewCount"] = data.review_count;
                            //var element = JSON.stringify(productJSONLD);
                            var scriptTag = document.getElementById("product-schema");
                            var scriptTagObject = JSON.parse(scriptTag.innerHTML);
                            scriptTagObject["aggregateRating"] = aggregateRating;
                            scriptTag.innerHTML = JSON.stringify(scriptTagObject);
                        }

                        if (config.component == "ReviewDisplay") {
                            var $revMain = $(document).find("[data-pr-component=ReviewDisplay]");

                            $revMain.find(".pr-snippet-write-review-link").text($revMain.attr("data-write-text"));
                            $revMain.addClass("loaded");
                            $revMain.toggleClass("no-reviews", data.review_count == undefined);
                        }

                        if (config.component == "QuestionSnippet") {
                            var $qaSnippet = $(document).find("[data-pr-component=QuestionSnippet]");
                            $qaSnippet.addClass("loaded");
                        }

                        if (config.component == "QuestionDisplay") {
                            var $qaMain = $(document).find("[data-pr-component=QuestionDisplay]");
                            $qaMain.find(".pr-qa-display-searchbar-input").attr("placeholder", $qaMain.attr("data-qa-text"));
                            $qaMain.find(".pr-qa-display-show-more-answers .pr-qa-display-text").text($qaMain.attr("data-see-all-qa-text"));
                        }
                    }
                });
            }
        });

        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments); // eslint-disable-line no-undef
        };
        pwr("render", renderArray);// eslint-disable-line no-undef

        $(document).on("click", ".pr-snippet-rating-decimal", function () {
            if ($(".pr-review-snapshot").length > 0) {
                scrollAnimate($(".pr-review-snapshot"));
            }
        });
    }
};
