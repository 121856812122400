"use strict";
var focusHelper = require("base/components/focus");
var validateForm = require("base/components/formValidation");
var clientSideValidation = require("storefront/components/clientSideValidation");

var isCartPage = $(".cart-header").length > 0;

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($("#quickViewModal").hasClass("show") && !$(".product-set").length) {
        pid = $($el).closest(".modal-content").find(".product-quickview").data("pid");
    } else if ($(".product-set-detail").length || $(".product-set").length) {
        pid = $($el).closest(".product-detail").find(".product-id").text();
    } else {
        pid = $(".product-detail:not(\".bundle-item\")").data("pid");
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $(".set-items").length) {
        quantitySelected = $($el).closest(".product-detail").find(".js-qty-input");
    } else if ($el && $(".product-bundle").length) {
        var quantitySelectedModal = $($el).closest(".modal-footer").find(".js-qty-input");
        var quantitySelectedPDP = $($el).closest(".pdp-buy-box").find(".js-qty-input");
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $(".js-qty-input");
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    var hasSelectedValue = false;

    attr.values.forEach(function (attrValue) {
        var selectedColorValueText;

        var $attrValue = $productContainer.find("[data-attr=\"" + attr.id + "\"] [data-attr-value=\"" +
            attrValue.value + "\"]");
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass("selected");
            $attrValue.siblings(".selected-assistive-text").text(msgs.assistiveSelectedText);
            selectedColorValueText = attrValue.displayValue;

            $productContainer.find(".js-selected-color").text(selectedColorValueText);
            hasSelectedValue = true;
        } else {
            $attrValue.removeClass("selected");
            $attrValue.siblings(".selected-assistive-text").empty();
        }

        if (attrValue.url) {
            $swatchButton.attr("data-url", attrValue.url);
        } else {
            $swatchButton.removeAttr("data-url");
        }

        // Disable if not selectable
        $attrValue.removeClass("selectable unselectable disabled");
        $attrValue.addClass(attrValue.selectable ? "selectable" : "unselectable disabled");
        $swatchButton.toggleClass("disabled", !attrValue.selectable);
    });

    if (!hasSelectedValue) {
        $productContainer.find(".js-selected-color").text("");
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer, msgs) {
    var $attr = "[data-attr=\"" + attr.id + "\"]";
    var $defaultOption = $productContainer.find($attr + " .select-" + attr.id + " option:first");
    $defaultOption.attr("value", attr.resetUrl);
    var selectedPillValueText;
    var hasSelectedValue = false;

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find("[data-attr=\"" + attr.id + "\"] [data-attr-value=\"" +
            attrValue.value + "\"]");

        if (attrValue.selected) {
            let selectedPill = ".js-selected-pill";
            $attrValue.addClass("selected");
            $attrValue.siblings(".selected-assistive-text").text(msgs.assistiveSelectedText);
            selectedPillValueText = attrValue.displayValue;
            if (selectedPillValueText.includes("$")) {
                selectedPill = ".js-amount-pill";
            }
            $productContainer.find(selectedPill).text(selectedPillValueText);
            hasSelectedValue = true;
        } else {
            $attrValue.removeClass("selected");
            $attrValue.siblings(".selected-assistive-text").empty();
        }

        // Disable if not selectable
        $attrValue.removeClass("selectable unselectable disabled");
        $attrValue.addClass(attrValue.selectable ? "selectable" : "unselectable disabled");

        if (attrValue.url) {
            $attrValue.attr("data-url", attrValue.url);
        } else {
            $attrValue.removeAttr("data-url");
        }
    });

    if (!hasSelectedValue) {
        $productContainer.find(".js-selected-pill").text("");
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ["color"];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer, msgs);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    $($productContainer).trigger("product:updateAvailability", {
        product: response.product,
        $productContainer: $productContainer,
        message: null,
        resources: response.resources
    });

    $($productContainer).find("[data-tag]").each(function () {
        updateTag($(this), response.product);
    });
}

/**
 * Update product tags accoding to variation response
 */
function updateTag($tag, response) {
    var tagContent = "";
    var showTag = false;

    switch ($tag.attr("data-tag")) {
        case "availabilityTag":
            showTag = response.availabilityTag.type != "None";
            tagContent = response.availabilityTag.title;
            break;
        case "nameExtension":
            showTag = response.nameExtension != null;
            tagContent = response.nameExtension;
            break;
    }

    $tag.find(".js-pdp-tag").html(tagContent);
    $tag.toggleClass("d-none", !showTag);
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return "";
    }

    var html = "";

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === "mainAttributes") {
            attributeGroup.attributes.forEach(function (attribute) {
                html += "<div class=\"attribute-values\">" + attribute.label + ": "
                    + attribute.value + "</div>";
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find(".product-options").empty().html(optionsHtml);
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateImageCarousels(galleryItems, $productContainer) {
    var $galleryItemsContainer = $productContainer.find(".js-pdp-images");

    $galleryItemsContainer.html(galleryItems);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents(".choose-bonus-product-dialog").length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === "variant";
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent(".bonus-product-item")
                .data("pid", response.product.id);

            $productContainer.parent(".bonus-product-item")
                .data("ready-to-order", response.product.readyToOrder);
        }
    }

    const $deliverOption = $productContainer.find(".js-delivery-option");
    if (response.updateVariationUrl) {
        $productContainer.find(".js-delivery-option").attr("data-url", response.updateVariationUrl);
    }

    if (response.product && response.product.variableSelling) {
        var variableSelling = response.product.variableSelling;
    
        $deliverOption.each(function () {
            var $deliveryOption = $(this);
            var $deliveryOptionMsg = null;

            $deliveryOption.attr("data-id", response.product.id);
    
            if ($deliveryOption.hasClass("js-a-product-delivery-bopis")) {
                $deliveryOptionMsg = $deliveryOption
                    .closest("div")
                    .find(".js-delivery-option-label .js-delivery-option-msg");
    
                if ($deliveryOptionMsg) {
                    var bopisMessage = variableSelling.enabled && variableSelling.sts > 1
                        ? variableSelling.resources.sts
                        : "";
                    $deliveryOptionMsg.text(bopisMessage);
                }
            }
    
            if ($deliveryOption.hasClass("js-a-product-delivery-shipment")) {
                $deliveryOptionMsg = $deliveryOption
                    .closest("div")
                    .find(".js-delivery-option-label .js-sth-msg");
    
                if ($deliveryOptionMsg) {
                    var shipmentMessage = variableSelling.enabled && variableSelling.sth > 1
                        ? variableSelling.resources.sth
                        : "";
                    $deliveryOptionMsg.text(shipmentMessage);
                }
            }
        });
    }

    if ($deliverOption && $deliverOption.data() && $deliverOption.data().isPdpAttr) {
        updateDeliveryOptionsVariationUrl($productContainer, response.product.variationAttributes);
    }

    //Update favorites
    if (response.product.isInWishlist) {
        $productContainer.find(".js-wish-list")
            .attr("data-pid", response.product.id)
            .addClass("js-wishlist-tile-remove")
            .removeClass("js-wishlist-tile-add")
            .find("use").attr("href", "#icon-full-heart");
    } else {
        $productContainer.find(".js-wish-list")
            .attr("data-pid", response.product.id)
            .addClass("js-wishlist-tile-add")
            .removeClass("js-wishlist-tile-remove")
            .find("use").attr("href", "#icon-empty-heart");
    }

    //Update my alerts
    if ((response.product.available && response.product.readyToOrder && !response.product.priceV2.isSalePrice && ($(".js-is-show-my-alert").length > 0 || $(".js-is-blue-core").length > 0)) || (!response.product.available || !response.product.readyToOrder) && !response.product.hideMyAlertOptionsOnPDP && ($(".js-is-show-my-alert").length > 0 || $(".js-is-blue-core").length > 0)) {
        if ($(".js-is-show-my-alert").length > 0) {
            $(".pdp-bottom-alert", $productContainer).html(response.product.myAletBlock);
        }
        $(".price-alert-link", $productContainer).html(response.resources.alertCta);
        $(".js-product-alert", $productContainer).removeClass("d-none").addClass("d-flex");
        $(".separator-product-details", $productContainer).removeClass("d-none").addClass("d-flex");
    } else {
        $(".js-product-alert", $productContainer).removeClass("d-flex").addClass("d-none");
        $(".separator-product-details", $productContainer).removeClass("d-flex").addClass("d-none");
    }

    if ((!response.product.available || !response.product.readyToOrder) && !response.product.hideMyAlertOptionsOnPDP && ($(".js-is-show-my-alert").length > 0 || $(".js-is-blue-core").length > 0)) {
        $(".pdp-bottom-alert", $productContainer).removeClass("d-none");
    } else if ((response.product.available && response.product.readyToOrder && !response.product.priceV2.isSalePrice)) {
        $(".pdp-bottom-alert", $productContainer).addClass("d-none");
    }

    // Update Bundles on Bundles Details page
    if (response.product.bundledProducts && $(".container").hasClass("bundle-detail-page")) {
        var bundleItemsHtmlString = "";
        $(".bundle-items-row").html("");

        response.product.bundledProducts.forEach(product => {
            bundleItemsHtmlString += `<div class="col-12 col-lg-4 bundle-item" data-pid="${product.id}">
                <!-- Product Image -->
                <picture>
                    <img class="d-block img-fluid"
                        decoding="async"
                        src="${product.images.pdpMain[0].url}"
                        alt="${product.productName}" />
                </picture>
                <div class="attributes-wrapper">
                    <!-- Product Name -->
                    <span class="product-id d-none">${product.id}</span>
                    <h1 class="product-name margin-4-bot bold">${product.productName}</h1>

                    <div class="margin-16-bot">
                        <a class="text text-sm text-underline text-color-blue-melamine medium" href="${product.selectedProductUrl}">
                            See Full Item Details
                        </a>
                    </div>

                    <div class="attributes">
                        <!-- Quantity -->
                        <span class="quantity non-input-label text text-lg text-color-t200 bold" data-quantity="${product.selectedQuantity}">
                            Quantity: ${parseInt(product.selectedQuantity, 10).toString()}
                        </span>
                    </div>
                </div>
            </div>`;
        });

        $(".bundle-items-row").html(bundleItemsHtmlString);
    }

    // Update primary images
    updateImageCarousels(response.product.imageHTML, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $(".prices .price", $productContainer).length
            ? $(".prices .price", $productContainer)
            : $(".prices .price");
        $priceSelector.replaceWith(response.product.renderedPriceV2);
    }

    // Update availability
    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find(".select-bonus-product");
        $selectButton.trigger("bonusproduct:updateSelectButton", {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $("button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global").trigger("product:updateAddToCart", {
            product: response.product, $productContainer: $productContainer, resources: response.resources
        }).trigger("product:statusUpdate", response.product);
    }

    // Update attributes
    $productContainer.find(".main-attributes").empty()
        .html(getAttributesHtml(response.product.attributes));

    if (response.product.variableSelling.enabled) {
        $productContainer.find(".product-name").text(response.product.productName);
    }

    $("body").trigger("reinitCarousels");
}

/**
* Updates the delivery option variation URLs
* @param {jQuery} $productContainer - the product container element
* @param {Array} variationAttributes - the variation attributes array
*/
function updateDeliveryOptionsVariationUrl($productContainer, variationAttributes) {
    const DELIVERY_ATTRIBUTE_ID = "delivery";

    if (variationAttributes) {
        const deliverOptionVariationAttributes = variationAttributes.find(function (attr) {
            return attr.attributeId === DELIVERY_ATTRIBUTE_ID;
        });
        if (deliverOptionVariationAttributes) {
            for (let value of deliverOptionVariationAttributes.values) {
                $productContainer.find("[data-attr-value=" + value.value + "]").attr("data-url", value.url);
            }
        }
    }
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent(".bonus-product-item").length <= 0) {
        getQuantitySelector($productContainer).attr("data-url", quantities[0].url);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {boolean} isDeliveryOptionChange - whether the attribute change is for a delivery option
 */
function attributeSelect(selectedValueUrl, $productContainer, isDeliveryOptionChange) {
    if (selectedValueUrl) {
        $("body").trigger("product:beforeAttributeSelect",
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: "GET",
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $("body").trigger("product:afterAttributeSelect",
                    { data: data, container: $productContainer });
                $.spinner().stop();

                if (data.product.variationAttributes && !isDeliveryOptionChange) {
                    window.dispatchEvent(new CustomEvent("pdpSwatchSelect", {
                        detail: {
                            products: [
                                {
                                    id: data.product.aPage.id,
                                    color: data.product.variationAttributes[0].displayValue,
                                    bopisStatus: data.product.aPage.bopisStatus,
                                    onlineStatus: data.product.aPage.onlineStatus,
                                    sku: data.product.aPage.sku
                                }
                            ]
                        }
                    }));

                    var $ugcContainer = $(".js-ugc-container");

                    if ($ugcContainer.length > 0) {
                        $ugcContainer.attr("data-id", data.product.aPage.id);
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $(".add-to-cart-url").val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".choice-of-bonus-product");
    var footer = $html.find(".modal-footer").children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $(".modal-body").spinner().start();

    if ($("#chooseBonusProductModal").length !== 0) {
        $("#chooseBonusProductModal").remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = "<!-- Modal -->"
        + "<div class=\"modal fade\" id=\"chooseBonusProductModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<span class=\"enter-message sr-only\" ></span>"
        + "<div class=\"modal-dialog choose-bonus-product-dialog\" "
        + "data-total-qty=\"" + data.maxBonusItems + "\""
        + "data-UUID=\"" + data.uuid + "\""
        + "data-pliUUID=\"" + data.pliUUID + "\""
        + "data-addToCartUrl=\"" + data.addToCartUrl + "\""
        + "data-pageStart=\"0\""
        + "data-pageSize=\"" + data.pageSize + "\""
        + "data-moreURL=\"" + data.showProductsUrlRuleBased + "\""
        + "data-bonusChoiceRuleBased=\"" + data.bonusChoiceRuleBased + "\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        + "    <span class=\"\">" + data.labels.selectprods + "</span>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span aria-hidden=\"true\">&times;</span>"
        + "        <span class=\"sr-only\"> </span>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-body\"></div>"
        + "<div class=\"modal-footer\"></div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
    $(".modal-body").spinner().start();

    $.ajax({
        url: bonusUrl,
        method: "GET",
        dataType: "json",
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $("#chooseBonusProductModal .modal-body").empty();
            $("#chooseBonusProductModal .enter-message").text(response.enterDialogMessage);
            $("#chooseBonusProductModal .modal-header .close .sr-only").text(response.closeButtonText);
            $("#chooseBonusProductModal .modal-body").html(parsedHtml.body);
            $("#chooseBonusProductModal .modal-footer").html(parsedHtml.footer);
            $("#chooseBonusProductModal").modal("show");
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function handlePostCartAdd(response, $button) {
    $(".minicart").trigger("count:update", response);
    var $currentProduct = $button.closest(".product-detail");

    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if (response.error) {
            $(".add-to-cart-messages", $currentProduct).html("");

            if ($currentProduct.find(".add-to-cart-messages").length === 0) {
                $currentProduct.find(".js-add-to-cart-messaging").append(
                    "<div class=\"add-to-cart-messages\"></div>"
                );
            }

            $(".add-to-cart-messages", $currentProduct).append(
                "<span class=\"text-sm text-color-e300 margin-8-bot d-block\">"
                + response.message
                + "</span>"
            );

            $button.attr("disabled", true);
            $(".add-to-cart-global").attr("disabled", true);
        }
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $(".bundle-item").each(function () {
        childProducts.push({
            pid: $(this).data("pid").toString(),
            quantity: parseInt($(this).find("label.quantity").data("quantity"), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find(".product-option")
        .map(function () {
            var $elOption = $(this).find(".options-select");
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find("option[value=\"" + urlValue + "\"]")
                .data("value-id");
            return {
                optionId: $(this).data("option-id"),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: "GET",
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Updates custom QTY input URL
 * @param {jQuery} $input
 */
function updateQtyURL($input) {
    var url = $input.attr("data-url");

    var urlParams = new URLSearchParams(url.split("?")[1]);
    urlParams.set("quantity", $input.val());
    $input.attr("data-url", url.split("?")[0] + "?" + urlParams.toString());
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal: function () {
        $("body").on("shown.bs.modal", "#chooseBonusProductModal", function () {
            $("#chooseBonusProductModal").siblings().attr("aria-hidden", "true");
            $("#chooseBonusProductModal .close").focus();
        });
    },

    onClosingChooseBonusProductModal: function () {
        $("body").on("hidden.bs.modal", "#chooseBonusProductModal", function () {
            $("#chooseBonusProductModal").siblings().attr("aria-hidden", "false");
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $("body").on("keydown", "#chooseBonusProductModal", function (e) {
            var focusParams = {
                event: e,
                containerSelector: "#chooseBonusProductModal",
                firstElementSelector: ".close",
                lastElementSelector: ".add-bonus-products"
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on("click", "[data-attr=\"color\"] button", function (e) {
            e.preventDefault();

            if ($(this).attr("disabled") || $(this).find(".color-value").hasClass("selected")) {
                return;
            }

            var $productContainer = $(this).closest(".set-item");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".product-detail");
            }

            attributeSelect($(this).attr("data-url"), $productContainer, false);
        });
    },

    selectAttribute: function () {
        $(document).on("click", ".js-pill-attr", function (e) {
            e.preventDefault();

            if ($(this).attr("disabled") || $(this).hasClass("selected")) {
                return;
            }

            var $productContainer = $(this).closest(".set-item");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".product-detail");
            }

            attributeSelect($(this).attr("data-url"), $productContainer, false);
        });
    },

    availability: function () {
        $(document).on("change", ".js-qty-input", function (e) {
            e.preventDefault();

            // Return if invalid
            if ($(this).val() == "") {
                $(this).val($(this).attr("min"));
            }

            updateQtyURL($(this));

            var $productContainer = $(this).closest(".product-detail");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".modal-content").find(".product-quickview");
            }

            // Restore button and messaging to default
            $productContainer.find(".js-add-to-cart-messaging").html("");
            $productContainer.find(".add-to-cart").attr("disabled", false);
            $(".add-to-cart-global").attr("disabled", false);
        });
    },

    addToCart: function () {
        $(document).on("click", "button.add-to-cart, button.add-to-cart-global", function (e) {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var $targetAddToCart = $(e.target);
            var $button = $(this);

            $("body").trigger("product:beforeAddToCart", this);

            if ($(".set-items").length && $(this).hasClass("add-to-cart-global")) {
                setPids = [];

                $(".product-detail").each(function () {
                    if (!$(this).hasClass("product-set-detail")) {
                        setPids.push({
                            pid: $(this).find(".product-id").text(),
                            qty: $(this).find(".js-qty-input").val(),
                            options: getOptions($(this)),
                            setId: $targetAddToCart.data("pid"),
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest(".product-detail");
            if (!$productContainer.length) {
                $productContainer = $(this).closest(".quick-view-dialog").find(".product-detail");
            }

            addToCartUrl = getAddToCartUrl();
            var pickupRadioSelected = $productContainer.find("input[value=storeId]").is(":checked");

            var $deliveryOpt = $(".js-delivery-option:checked");
            var selectedDeliveryOpt = "";
            if ($deliveryOpt.length > 0) {
                selectedDeliveryOpt = $deliveryOpt[0].id.indexOf("ship-to-home") > -1 ? "STH" : "ISPU";
            }

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this)),
                pickupRadioSelected: pickupRadioSelected,
                selectedDeliveryOpt: selectedDeliveryOpt
            };

            if (form.quantity == 0) {
                form.quantity = 1;
            }

            if (!$(".bundle-item").length) {
                form.options = getOptions($productContainer);
            }

            // Check if store pickup is selected
            var selectedStoreID = $productContainer.find(".js-store-name").attr("data-store-id");

            // Trigger store selection modal
            if (pickupRadioSelected && (selectedStoreID == "null" || selectedStoreID == undefined)) {
                $productContainer.find(".change-store").first().trigger("click");
                return;
            }

            var $isDigitalGiftCard = $("#isDigitalGiftCard");
            var $eGiftForm  = $(".js-egift-form");
            form.isDigitalGiftCard = false;
            if ($isDigitalGiftCard.length > 0 && $isDigitalGiftCard.val() === "true") {
                clientSideValidation.functions.clearForm(".js-egift-form");
                $eGiftForm.serializeArray().forEach(function (field) {
                    form[field.name] = field.value;
                });
                form.isDigitalGiftCard = true;
            }

            $(this).trigger("updateAddToCartFormData", form, pickupRadioSelected);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: "POST",
                    data: form,
                    success: function (data) {
                        if (data && !data.success && data.fields) {
                            validateForm($eGiftForm, data);
                            $.spinner().stop();
                            return;
                        }
                        handlePostCartAdd(data, $button);
                        $("body").trigger("product:afterAddToCart", data);
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                        if (form.pidsObj) {
                            $(".minicart .popover").addClass("set-notification");
                            window.pidsObj = JSON.parse(form.pidsObj);
                        }
                        if (data.error) {
                            window.dispatchEvent(new CustomEvent("addToCartFail", {detail: {data: data.message}}));
                        } else {
                            window.dispatchEvent(new CustomEvent("addToCartSuccess", {
                                detail: {
                                    ...data.aCart,
                                }
                            }));
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    selectBonusProduct: function () {
        $(document).on("click", ".select-bonus-product", function () {
            var $choiceOfBonusProduct = $(this).parents(".choice-of-bonus-product");
            var pid = $(this).data("pid");
            var maxPids = $(".choose-bonus-product-dialog").data("total-qty");
            var submittedQty = parseInt($choiceOfBonusProduct.find(".bonus-quantity-select").val(), 10);
            var totalQty = 0;
            $.each($("#chooseBonusProductModal .selected-bonus-products .selected-pid"), function () {
                totalQty += $(this).data("qty");
            });
            totalQty += submittedQty;
            var optionID = $choiceOfBonusProduct.find(".product-option").data("option-id");
            var valueId = $choiceOfBonusProduct.find(".options-select option:selected").data("valueId");
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ""
                + "<div class=\"selected-pid row\" "
                + "data-pid=\"" + pid + "\""
                + "data-qty=\"" + submittedQty + "\""
                + "data-optionID=\"" + (optionID || "") + "\""
                + "data-option-selected-value=\"" + (valueId || "") + "\""
                + ">"
                + "<div class=\"col-sm-11 col-9 bonus-product-name\" >"
                + $choiceOfBonusProduct.find(".product-name").html()
                + "</div>"
                + "<div class=\"col-1\"><i class=\"fa fa-times\" aria-hidden=\"true\"></i></div>"
                + "</div>"
                ;
                $("#chooseBonusProductModal .selected-bonus-products").append(selectedBonusProductHtml);
                $(".pre-cart-products").html(totalQty);
                $(".selected-bonus-products .bonus-summary").removeClass("alert-danger");
            } else {
                $(".selected-bonus-products .bonus-summary").addClass("alert-danger");
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on("click", ".selected-pid", function () {
            $(this).remove();
            var $selected = $("#chooseBonusProductModal .selected-bonus-products .selected-pid");
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data("qty"), 10);
                });
            }

            $(".pre-cart-products").html(count);
            $(".selected-bonus-products .bonus-summary").removeClass("alert-danger");
        });
    },
    enableBonusProductSelection: function () {
        $("body").on("bonusproduct:updateSelectButton", function (e, response) {
            $("button.select-bonus-product", response.$productContainer).attr("disabled",
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $("button.select-bonus-product", response.$productContainer).data("pid", pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on("click", ".show-more-bonus-products", function () {
            var url = $(this).data("url");
            $(".modal-content").spinner().start();
            $.ajax({
                url: url,
                method: "GET",
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $(".modal-body").append(parsedHtml.body);
                    $(".show-more-bonus-products:first").remove();
                    $(".modal-content").spinner().stop();
                },
                error: function () {
                    $(".modal-content").spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on("click", ".add-bonus-products", function () {
            var $readyToOrderBonusProducts = $(".choose-bonus-product-dialog .selected-pid");
            var queryString = "?pids=";
            var url = $(".choose-bonus-product-dialog").data("addtocarturl");
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data("qty"), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data("optionid") && $(this).data("option-selected-value")) {
                        option = {};
                        option.optionId = $(this).data("optionid");
                        option.productId = $(this).data("pid");
                        option.selectedValueId = $(this).data("option-selected-value");
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data("pid"),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($(".pre-cart-products").html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + "&uuid=" + $(".choose-bonus-product-dialog").data("uuid");
            queryString = queryString + "&pliuuid=" + $(".choose-bonus-product-dialog").data("pliuuid");
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: "POST",
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $("#chooseBonusProductModal").modal("hide");
                        if ($(".add-to-cart-messages").length === 0) {
                            $("body").append("<div class=\"add-to-cart-messages\"></div>");
                        }
                        $(".add-to-cart-messages").append(
                            "<div class=\"alert alert-danger add-to-basket-alert text-center\""
                            + " role=\"alert\">"
                            + data.errorMessage + "</div>"
                        );
                        setTimeout(function () {
                            $(".add-to-basket-alert").remove();
                        }, 3000);
                    } else {
                        $(".configure-bonus-product-attributes").html(data);
                        $(".bonus-products-step2").removeClass("hidden-xl-down");
                        $("#chooseBonusProductModal").modal("hide");

                        if ($(".add-to-cart-messages").length === 0) {
                            $("body").append("<div class=\"add-to-cart-messages\"></div>");
                        }
                        $(".minicart-quantity").html(data.totalQty);
                        $(".add-to-cart-messages").append(
                            "<div class=\"alert alert-success add-to-basket-alert text-center\""
                            + " role=\"alert\">"
                            + data.msgSuccess + "</div>"
                        );
                        setTimeout(function () {
                            $(".add-to-basket-alert").remove();
                            if (isCartPage) {
                                location.reload();
                            }
                        }, 1500);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    miniCartReportingUrl: miniCartReportingUrl,
    updateQtyURL: function ($input) {
        updateQtyURL($input);
    },
    createFAQSchemaMarkupOnPageLoad: function () {
        /*
            TODO when implementing FAQ tag, create an element with id "js-faq-schema-markup"
            and attribute "data-faq-schema-markup" containing the schema markup structure.
            this function will be executed on page load and add the schema into <head/> tag.
        */
        const $faqMarkup = $("#js-faq-schema-markup");
        if ($faqMarkup.length === 0) return;

        const faqSchemaMarkup = $faqMarkup.attr("data-faq-schema-markup");
        const $scriptTag = $(`<script>${faqSchemaMarkup}</script>`);
        $scriptTag.attr("type", "application/ld+json");
        $("head").append($scriptTag);
    }
};
