"use strict";

module.exports = function () {
    document.addEventListener("init.slick", function (event) {
        let $this = $(event.detail.selector);
        let slidesToShow = event.detail.slidesToShow;
        let slidesToScroll = event.detail.slidesToScroll;
        removeSlotEmptyTiles();
        loadSingleCarousel($this, slidesToShow, slidesToScroll, true);
    });

    /**
     * Removes empty tiles from the DOM
     */
    function removeEmptyTiles() {
        let offLineTiles = $("[data-tile-online]");

        if (offLineTiles.length > 0) {
            offLineTiles.each(function () {
                let $this = $(this);

                if ($this.length > 0 && $this.children().length < 2 && $this.find(".js-a-product-click").length < 1) {
                    $this.parents(".experience-commerce_assets-productTile").remove();
                }
            });
        }
    }

    /**
     * Removes empty tiles from the product slide wrapper
     */
    function removeSlotEmptyTiles() {
        let wrappers = $(".product-slide-wrapper");

        wrappers.each(function (index, wrapper) {
            let $wrapper = $(wrapper);

            if ($($wrapper).children(".product").length === 0) {
                $wrapper.remove();
            }
        });
    }

    /**
     * Removes empty slides from a carousel
     * @param {jQuery} carousel - the carousel element
     */
    function removeEmptySlides(carousel) {
        let slickSlides = carousel.find(".slick-slide");

        slickSlides.each(function (index, slide) {
            let $slide = $(slide);

            if ($slide.find(".product").length === 0) {
                carousel.slick("slickRemove", index);
            }
        });

        slickSlides = carousel.find(".slick-slide");

        if (slickSlides.length === 0 && $(document).innerWidth() > 991) {
            carousel.parents(".carousel-container").hide();
        }
    }

    /**
     * Loads carousels on the page
     */
    function loadCarousels() {

        removeEmptyTiles();

        $(".js-slide-carousel").each(function () {
            let $this = $(this);

            resizeHeight($this);

            loadSingleCarousel($this);
        });
    }

    /**
     * Loads a single carousel with specified settings
     * @param {jQuery} container - the container element for the carousel
     * @param {number|null} slidesToShow - number of slides to show at a time (default: null)
     * @param {number|null} slidesToScroll - number of slides to scroll at a time (default: null)
     * @param {boolean} clearEmptyCarousels - whether to clear empty carousels (default: false)
     * @returns {void}
     */
    function loadSingleCarousel(container, slidesToShow = null, slidesToScroll = null, clearEmptyCarousels = false) {
        if (container.length === 0) {
            return;
        }

        let dataSettings = container.parent().data();
        let responsive = dataSettings.carouselBehavior ? [
            {
                breakpoint: 991,
                settings: "unslick"
            }
        ] : [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: dataSettings.slidesToDisplaySm,
                    slidesToScroll: 1,
                }
            }
        ];

        let settings = {
            slidesToShow: slidesToShow ? slidesToShow : dataSettings.slidesToDisplayLg == 5 && dataSettings.peek ? 5.17 : dataSettings.slidesToDisplayLg,
            autoplay: dataSettings.autoplay,
            autoplaySpeed: dataSettings.autoplaySpeed,
            dots: dataSettings.dots,
            infinite: !dataSettings.peek,
            slidesToScroll: slidesToScroll ? slidesToScroll : dataSettings.slidesToScrollLg,
            arrows: true,
            prevArrow:"<svg class='icon-lg arrow-left icon-color-t300'><use href='#chevron-left'></use></svg>",
            nextArrow:"<svg class='icon-lg arrow-right icon-color-t300'><use href='#chevron-right'></use></svg>",
            pauseIcon: "<span class='slick-pause-icon' aria-hidden='true'><svg class='icon-sm icon-pause d-block'><use href='#icon-pause'></use></svg></span>",
            playIcon: "<span class='slick-play-icon' aria-hidden='true'><svg class='icon-sm icon-play d-block'><use href='#icon-play'></use></svg></span>",
            responsive: responsive
        };

        if (container.hasClass("slick-initialized")) {
            container.slick("unslick");
        }

        container.slick(settings);
        container.removeClass("opacity-zero").addClass("opacity-one");

        resizeHeight(container);

        if (clearEmptyCarousels) {
            removeEmptySlides(container);
        }
    }

    /**
     * Sets the equal height for elements with a given class within a container
     * @param {string} container - the container element selector
     * @param {string} className - the class name of the elements to set equal height
     */
    function equalHeight(container, className) {
        let maxHeight = 0;
        let breakpointValue = $(document).innerWidth();

        $(container).find(className).removeAttr("style");

        if (breakpointValue < 992) {
            $(container).find(className).each(function () {
                if ($(this).innerHeight() > maxHeight) {
                    maxHeight = $(this).innerHeight();
                }
            });
            $(container).find(className).css("height", maxHeight);
        }
    }

    /**
     * Resize the height of a container based on the current breakpoint value
     * @param {HTMLElement} container - the container element to resize
     * @returns {void}
     */
    function resizeHeight(container) {
        equalHeight(container, ".content-block-wrapper");
        equalHeight(container, ".herobanner-wrapper");

        $(container).find(".herobanner-wrapper").removeClass("row").addClass("d-flex flex-sm-column flex-lg-row");
    }

    function initRecommendationLazy() {
        //event listener for lazy loading
        // Create an Intersection Observer instance
        const slotObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadCarouselContent();
                    // Stop observing the content slot
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe all the content slots
        const contentSlots = $(".js-replace-recommendations");

        if (contentSlots && contentSlots.length > 0) {
            $.each(contentSlots, function (indexInArray, contentSlot) {
                slotObserver.observe(contentSlot);
            });
        }

        // Function to load the content for a specific content slot
        function loadCarouselContent() {
            // Make an AJAX request to fetch the content for the given slotId
            // Replace the placeholder content with the fetched content
            // Example:
            $.each(contentSlots, function (indexInArray, tile) {
                var tileImages = $(tile).find("[data-lazy-src]");
                $.each(tileImages, function (imgIndex, image) {
                    $(image).attr("src", $(image).data("lazy-src"));
                });
            });
        }
    }

    $(document).ready(function () {
        setTimeout(function () {
            initRecommendationLazy();
            loadCarousels();
        }, 1000);
    });

    $(document).on("recommendation:lazy", function () {
        initRecommendationLazy();
    });

    $(window).on("resize", loadCarousels);

};
