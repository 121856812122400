"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const { 
    PAGE_LOAD,
    BUY_AGAIN_FILTER,
    BUY_AGAIN_CLICK,
    // CART_ADD,
    // CART_ERROR,
} = require("../models/BuyAgain");

class BuyAgainAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof BuyAgainAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        super("BuyAgainAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof BuyAgainAnalytics
     */
    collect() {
        this.prepareDOMElements();
        this.getPageData();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    pageEvents() {
    }

    pageInterceptedEvents() {
        this.addNativeEventListeners("buyAgain:filterApplied", window, event => this.filterApplied(event));
    }

    /**
    * Triggers an analytics event when a filter is applied in the "Buy Again" section.
    * 
    * @param {Object} _event - The event object associated with the filter application.
    * @returns {void}
    */
    filterApplied(_event) {
        var eventData = _event.detail;
        var domData = {
            buyAgainFilter: eventData.buyAgainFilter
        };

        this.prepareSingleEvent(BUY_AGAIN_FILTER, domData);
    }

    /**
    * Handles the event when a user clicks on the "Buy Again" button.
    * Constructs the necessary data structure for the analytics event and triggers the event preparation.
    *
    * @param {Event} _event - The event object triggered by the "Buy Again" button click.
    * @param {HTMLElement} _event.dataset - The dataset attribute of the event target element.
    * @param {string} _event.dataset.productId - The product ID associated with the "Buy Again" button.
    */
    buyAgainClicked(_event) {
        var domData = {
            products: [
                {
                    id: _event.dataset.productId
                }
            ]
        };

        this.prepareSingleEvent(BUY_AGAIN_CLICK, domData);
    }

    pageDelayedEvents() {
        this.addEventWatcher("click", document.getElementsByClassName("js-buy-again-container"), ".js-buyagain-product-link", event => this.buyAgainClicked(event));
    }
}

module.exports = BuyAgainAnalytics;
