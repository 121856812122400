const {
    PAGE_CUSTOMER,
    PAGE_CART,
    PAGE_STORE,
    PAGE_INSTANCE,
    PRODUCT_DATA,
} = require("./Base");

const PAGE_LOAD = {
    eventName: "pageLoaded",
    eventData: {
        customer: {
            ...PAGE_CUSTOMER,
            ...PAGE_CART,
            ...PAGE_STORE,
        },
        order: {
            checkoutType: "",
        },
        page: {
            ...PAGE_INSTANCE,
            type: "shopping cart"
        },
        products: [
            {
                ...PRODUCT_DATA,
                categoryNames: [],
                fulfillmentMethod: "",
            }
        ]
    }
};

// Used on removed item from cart ???
const CART_UPDATE = {
    eventName: "cartUpdate",
    eventData: {
        products: [
            {
                id: "",
                price: "",
                newQuantity: "",
                oldQuantity: "",
                sku: "",
            }
        ]
    }
};

// Tracks products removed from cart ???
const CART_EMPTY = {
    eventName: "emptyCart",
    eventData: {
        products: [
            {
                id: "",
                price: "",
                quantity: "",
                sku: "",
            }
        ]
    }
};

const MOVE_TO_FAVORITES = {
    eventName: "moveToFavorites",
    eventData: {
        products: [
            {
                id: "",
                price: "",
                quantity: "",
                sku: "",
            }
        ]
    }
};

const CART_ERROR = {
    eventName: "cartError",
    eventData: {
        error: {
            type: "",
        }
    }
};

const EDIT_PRODUCT = {
    eventName: "editProduct",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const CHANGE_TO_BOPIS = {
    eventName: "bopisFulfillmentChange",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const CHANGE_TO_SHIPPING = {
    eventName: "shipFulfillmentChange",
    eventData: {
        products: [
            {
                id: "",
            }
        ]
    }
};

const EXIT_PAYPAL = {
    eventName: "paypalExit",
    eventData: {}
};

module.exports = {
    PAGE_LOAD,
    CART_UPDATE,
    CART_ERROR,
    CART_EMPTY,
    MOVE_TO_FAVORITES,
    EDIT_PRODUCT,
    CHANGE_TO_BOPIS,
    CHANGE_TO_SHIPPING,
    EXIT_PAYPAL
};
