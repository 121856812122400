"use strict";
const BaseAnalytics = require("../base/BaseAnalytics");

const {
    PAGE_LOAD,
    CHECKOUT_STEP,
    EXIT_PAYPAL,
    ORDER_REVIEW_EDIT,
    CHECKOUT_FORM_ERROR,
    TOKENIZATION_RESPONSE,
    FRAUD_RESPONSE,
    PAYMENT_RESPONSE
} = require("../models/Checkout");

class CheckoutAnalytics extends BaseAnalytics {
    /**
     * This constructor sets the @property { string } this.file to be able to track which module may be throwing an error.
     *
     * @memberof CheckoutAnalytics
     */
    constructor() {
        const DOM_PAGE_DATA = ["js-a-page-customer", "js-a-page-cart", "js-a-page-store", "js-a-page-data", "js-a-page-instance"];
        super("CheckoutAnalytics", DOM_PAGE_DATA, PAGE_LOAD);
    }

    /**
     * Initializing the class with collect(), engages all analytics listeners
     *
     * @memberof CheckoutAnalytics
     */
    collect() {
        this.prepareDOMElements();
        this.getPageData();
        this.baseEvents();
        this.baseInterceptedEvents();
        this.baseDelayedEvents();
        this.pageEvents();
        this.pageInterceptedEvents();
        this.pageDelayedEvents();
    }

    pageEvents() {

    }

    pageInterceptedEvents() {
        this.addNativeEventListeners("stepChange", window, event => this.prepareSingleEvent(CHECKOUT_STEP, event.detail));
        this.addNativeEventListeners("selectPaypal", window, () => this.prepareSingleEvent(EXIT_PAYPAL));
        this.addNativeEventListeners("reviewEdit", window, () => this.prepareSingleEvent(ORDER_REVIEW_EDIT));
        this.addNativeEventListeners("checkoutFormError", window, event => this.prepareSingleEvent(CHECKOUT_FORM_ERROR, event.detail));
        this.addNativeEventListeners("checkoutTokenizationResponse", window, event => this.prepareSingleEvent(TOKENIZATION_RESPONSE, event.detail));
        this.addNativeEventListeners("checkoutFraudResponse", window, event => this.prepareSingleEvent(FRAUD_RESPONSE, event.detail));
        this.addNativeEventListeners("checkoutPaymentResponse", window, event => this.prepareSingleEvent(PAYMENT_RESPONSE, event.detail));
    }

    pageDelayedEvents() {

    }
}

module.exports = CheckoutAnalytics;
