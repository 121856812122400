"use strict";

var base = require("../product/base");
var focusHelper = require("base/components/focus");
var powerReviews = require("powerreviews/product/details");
var debounce = require("lodash/debounce");
var inventoryModalHelper = require("storepickup/product/inventoryModal");
var utils = require("../components/utils");
var clientSideValidation = require("storefront/components/clientSideValidation");
var summaryHelpers = require("storefront/checkout/summary");
var isCartPage = $(".cart-header").length > 0;
var formValidation = require("base/components/formValidation");
var WISHLIST_DETAIL_ENDPOINT = "Wishlist-WishListDetails";
let rewardsHelper = require("../components/rewardsUpdate");

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */

function displayZipCodeMessage(data, button) {
    var status;
    if (data.success) {
        status = "alert-success";
    } else {
        status = "alert-danger";
    }

    if ($(".estimate-zip-code-message").length === 0) {
        $("body").append(
            "<div class='estimate-zip-code-message'></div>"
        );
    }
    $(".estimate-zip-code-message")
        .append("<div class='estimate-zip-code-alert text-center " + status + "' role='alert'>" + data.msg + "</div>");

    setTimeout(function () {
        $(".estimate-zip-code-message").remove();
        button.removeAttr("disabled");
    }, 3000);
}

function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf("?") !== -1 ? "&" : "?") + Object.keys(params).map(function (key) {
        return key + "=" + encodeURIComponent(params[key]);
    }).join("&");

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket() {
    let hasError = false;
    $(".product-card").each(function () {
        if ($(this).attr("data-cart-error") != "") {
            hasError = true;
        }
    });
    if (hasError) {
        $(".js-cart-error").removeClass("d-none");
        $(".valid-cart-error").addClass("show");
    } else {
        $(".js-cart-error").addClass("d-none");
        $(".valid-cart-error").removeClass("show");
    }
}

function togglePaypalCartButton(data) {
    $(".js-paypal-cart-button").removeClass("d-none");
    $(".js-paypal-cart-button-separator").removeClass("d-none");

    if (data.disablePaypal) {
        $(".js-paypal-cart-button").addClass("d-none");
        $(".js-paypal-cart-button-separator").addClass("d-none");
    }

    if (data.valid.error) {
        $("body").trigger("paypal:disableCartButton");
    } else {
        $("body").trigger("paypal:enableCartButton");
    }
}

/**
* Updates the BOPIS availability error message for a given product
* @param {Object} data - the data object containing the error message (basket or order model)
* @param {string} uuid - the UUID of the product
*/
function updateBopisAvailabilityErrorMessage(data, uuid) {
    const $productCard = $(".uuid-" + uuid);
    var isAvaialable = data.items.find((item) => item.UUID === uuid).available;

    if ($productCard.length > 0 && data) {
        const $errorWrapper = $productCard.find(".js-individual-error");

        if (data.valid.error && $errorWrapper.hasClass("d-none") && !isAvaialable) {
            $productCard.attr("data-cart-error", "scroll");
            $errorWrapper.find(".text").text(data.valid.message);
            $errorWrapper.removeClass("d-none");
        } else if (data.valid.error && !$errorWrapper.hasClass("d-none") && !isAvaialable) {
            $productCard.attr("data-cart-error", "scroll");
        } else {
            $productCard.attr("data-cart-error", "");
            $errorWrapper.addClass("d-none");
            $errorWrapper.find(".text").text("");
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $(".number-of-items").empty().append(data.resources.numberOfItems);
    $(".shipping-cost").empty().append(data.totals.totalShippingCost);
    $(".tax-total").empty().append(data.totals.totalTax);
    $(".grand-total").empty().append(data.totals.grandTotal);
    $(".grand-total-sum").empty().append(data.totals.grandTotal);
    $(".sub-total").empty().append(data.totals.subTotal);
    if (data.totals.totalSurchargeCost !== undefined) {
        $(".surcharge-cost").empty().append(data.totals.totalSurchargeCost.formatted);
    }
    $(".savings-total").empty().append(data.totals.savings);
    $(".minicart-quantity").empty().append(data.numItems);
    $(".cart-quantity").empty().append(data.numItems);
    $(".js-shippingShipmentsQuantity").empty().append(data.shipToHomeQuantity);
    $(".js-pickupShipmentsQuantity").empty().append(data.shipToStoreQuantity);
    updateCartZipCode(data);
    summaryHelpers.updateColoradoRetailFee(data && !data.isBOPISOnly ? data.totals : null);
    summaryHelpers.updateEarnToPoints(data);
    togglePaypalCartButton(data);
    $(".container-savings").toggleClass("d-none", !data.totals.hasSavings);
    $(".minicart-link").attr({
        "aria-label": data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $(".order-discount").removeClass("d-none");
        $(".order-discount-total").empty()
            .append("- " + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $(".order-discount").addClass("d-none");
    }

    if (data.totals.shippingLevelDiscountTotal.value != 0) {
        $(".shipping-discount").removeClass("d-none");
        $(".shipping-discount-total").empty().append(data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $(".shipping-discount").addClass("d-none");
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $(".item-" + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $(".item-" + item.UUID).empty();
        }
        $(".uuid-" + item.UUID + " .unit-price > .price-wrapper").empty().append(item.renderedPriceV2);
        $(".line-item-price-" + item.UUID + " .unit-price > .price-wrapper").empty().append(item.renderedPriceV2);
        $(".item-total-" + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });

    if (data.totals.promotions) {
        $(".total-promotions").removeClass("d-none");
        $(".total-promotions").empty();
        data.totals.promotions.forEach(function (promotion) {
            let $htmlPromotion = `<div class="d-flex align-items-center justify-content-between col-12">
            <span class="text text-lg medium text-color-t300">
                ${promotion.promotionName}
            </span>
            <span class="text text-lg medium text-color-t300">
                ${promotion.totalDiscount}
            </span>
            </div>`;
            $(".total-promotions").append($htmlPromotion);
        });
    } else {
        $(".total-promotions").addClass("d-none");
    }
}

function updateCartZipCode(data) {
    $(".js-zip-text").toggleClass("d-none", !data.zipCode).find(".js-new-zipcode").text(data.zipCode);
    $(".js-empty-zip-text").toggleClass("d-none", !!data.zipCode);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = `<div class="alert alert-danger alert-dismissible valid-cart-error fade show" role="alert">
        <svg class="icon icon-md margin-16-right">
            <use href="${"#icon-alert"}" />
        </svg>
        <span class="text text-sm medium text-color-e300">
            ${message}
        </span>
    </div>`;

    if (!$(".cart-error").children().length) {
        $(".cart-error").append(errorHtml);
    } else {
        $(".cart-error").empty();
        $(".cart-error").append(errorHtml);
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = "";

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $(".availability-" + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += "<p class=\"line-item-attributes\">" + message + "</p>";
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += "<p class=\"line-item-attributes line-item-instock-date\">"
                    + lineItem.availability.inStockDate
                    + "</p>";
            }
        }

        $(".availability-" + lineItem.UUID).html(messages);

        if (lineItem.pliStoreAvailability) {
            var $productCard = $(".product-card.uuid-" + lineItem.UUID);
            var $storeOptionElem = $productCard.find(".js-store-shipping-type").parents(".card-shipping-option");
            var availabilityHTML = "<div class='d-flex align-items-center'><span class='availability-dot " + lineItem.pliStoreAvailability.cssClass + "'></span><span class='text text-sm text-color-t200 medium'>" + lineItem.pliStoreAvailability.genericTitle + "</span></div>";

            $storeOptionElem.toggleClass("disabled unavailable", !lineItem.pliStoreAvailability.available);
            $storeOptionElem.find(".js-store-availability").html(availabilityHTML);
            $storeOptionElem.find(".stock-availability").toggleClass("d-none", (lineItem.pliStoreAvailability.type !== "limited" || lineItem.pliStoreAvailability.type === "limited" && lineItem.pliStoreAvailability.disableLowStockAlert));
        }
    }
}

function updateItemSurcharge(data, uuid) {
    let lineItem;
    let itemComponent = $("body").find(".uuid-" + uuid);
    let itemSurchargeValue = itemComponent.find(".js-shipping-surcharge");

    for (const element of data.items) {
        if (element.UUID === uuid) {
            lineItem = element;
            break;
        }
    }

    if (lineItem != null && lineItem.surcharge) {
        itemSurchargeValue.empty().append(lineItem.surcharge.formatted);
    } else {
        return;
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $(".uuid-" + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($("#editProductModal").length !== 0) {
        $("#editProductModal").remove();
    }
    var htmlString = "<div class=\"modal fade modal-aside edit-product-modal\" id=\"editProductModal\" tabindex=\"-1\" role=\"dialog\">"
        + "<span class=\"enter-message sr-only\" ></span>"
        + "<div class=\"modal-dialog quick-view-dialog\">"
        + "<!-- Modal content-->"
        + "<div class=\"modal-content\">"
        + "<div class=\"modal-header\">"
        + "    <h1 class=\"text text-xl bold\">Edit Options</h1>"
        + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
        + "        <span class=\"sr-only\"> </span>"
        + "        <svg class=\"icon-close icon-lg icon-color-t300\"><use href=\"#icon-close\"/></svg>"
        + "    </button>"
        + "</div>"
        + "<div class=\"modal-content-wrapper custom-scrollbar\">"
        +       "<div class=\"modal-body\"></div>"
        +       "<div class=\"modal-footer\"></div>"
        + "</div>"
        + "</div>"
        + "</div>"
        + "</div>";
    $("body").append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $("<div>").append($.parseHTML(html));

    var body = $html.find(".product-quickview");
    var footer = $html.find(".modal-footer").children();

    return { body: body, footer: footer };
}

/**
 * Counts the number of characters in the gift message textarea.
 */
function countCharacters(element) {
    let text = element.val();
    let count = text.length;
    let block = element.parents(".gift-message-block");
    block.find(".js-gift-message-count-current").text(count);
}

/**
 * Initializes the character count for the e-gift card message textarea
 * @returns {void}
 */
function iniGiftCardsCharactersCount() {
    $(".js-egift-card-message-textarea").keyup(function () {
        countCharacters();
    });
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $(".modal-body").spinner().start();
    $.ajax({
        url: editProductUrl,
        method: "GET",
        headers: {
            Accept: "*/*,application/json"
        },
        dataType: "json",
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $("#editProductModal .modal-body").empty();
            $("#editProductModal .modal-body").html(parsedHtml.body);
            $("#editProductModal .modal-footer").html(parsedHtml.footer);
            $("#editProductModal .modal-header .close .sr-only").text(data.closeButtonText);
            $("#editProductModal .enter-message").text(data.enterDialogMessage);
            $("#editProductModal").modal("show");
            $("body").trigger("editproductmodal:ready");

            // Initializing Quickview Carousel
            $(".product-quickview .js-quickview-images").slick({
                slidesToShow: 1,
                arrows: true,
                dots: true,
                infinite: false,
                prevArrow:"<svg class='icon-lg arrow-left icon-color-t300'><use href='#chevron-left'></use></svg>",
                nextArrow:"<svg class='icon-lg arrow-right icon-color-t300'><use href='#chevron-right'></use></svg>",
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            });

            $(".js-qty-button").on("click", function (e) {
                e.stopPropagation();
                var $input = $(e.target).parents(".qty-incrementer").find(".js-qty-input");
                var value = parseInt($input.val(), 10);
                var min = parseInt($input.attr("min"), 10);
                var max = parseInt($input.attr("max"), 10);
                var button = e.target;
                button = $(button).hasClass("js-qty-button") ? $(button) : $(button).parents(".js-qty-button");

                if (button.is("[data-plus]")) {
                    value++;
                } else {
                    value--;
                }

                if (min <= value && value <= max) {
                    $input.val(value);
                } else if (value >= max) {
                    return;
                }

                $(".invalid-feedback").removeClass("d-block");
                $(".qty-incrementer").removeClass("error");
            });

            $(".js-qty-input").on("input", function () {
                $(".invalid-feedback").removeClass("d-block");
                $(".qty-incrementer").removeClass("error");
            });

            powerReviews.init();
            initQtyFormatter();
            setTimeout(() => {
                let reviewWrapper = $(".review-wrapper");
                let reviewCount = $(".pr-snippet-review-count");
                var pwrLink = "<a href='"+reviewWrapper.data("url")+"'></a>";
                reviewCount.html($(pwrLink).append(reviewCount.text()));
            }, 300);
            clientSideValidation.invalid();
            iniGiftCardsCharactersCount();

            window.dispatchEvent(new CustomEvent("productEdit", {
                detail: {
                    ...data.aEditProductData,
                }
            }));
        },
        error: function () {
            return;
        }
    });
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    var status = data.success ? "alert-success" : "alert-danger";

    if ($(".wishlist-messages").length === 0) {
        $("body").append("<div class=\"wishlist-messages \"></div>");
    }
    $(".wishlist-messages")
        .append("<div class=\"add-to-wishlist-alert text-center " + status + "\">" + data.msg + "</div>");

    setTimeout(function () {
        $(".wishlist-messages").remove();
    }, 1500);
}

function quantityInputValidator(quantity, scope) {
    let minValue = parseInt($(scope).attr("min"), 10);
    let maxValue = parseInt($(scope).attr("max"), 10);
    let preSelectQty = parseInt($(scope).data("pre-select-qty"), 10);

    if (quantity >= 0) {
        if (quantity < minValue || quantity == 0) {
            $(scope).val(quantity);
            $(scope).parents(".card-product-footer").find(".remove-product").trigger("click");
            return false;
        } else if (quantity > maxValue) {
            quantity = maxValue;
            preSelectQty = preSelectQty > quantity ? quantity + 1 : quantity - 1;
        }
    } else {
        quantity = preSelectQty;
        $(scope).val(preSelectQty);
    }

    return {
        quantity: quantity,
        preSelectQty: preSelectQty
    };
}

function submitQuantityChange(scope, trigger = "") {
    let quantity = parseInt($(scope).val(), 10);
    let productID = $(scope).data("pid");
    let url = $(scope).data("action");
    let uuid = $(scope).data("uuid");
    let currentQuantity = quantity;

    // Return if invalid
    if (!$(scope)[0].validity.valid) {
        $(scope).val($(scope).val().replace(/\D/g, ""));
    }

    if (trigger == "minus") {
        quantity--;
    } else if (trigger == "plus") {
        quantity++;
    }

    let validation = quantityInputValidator(quantity, scope);

    if (!validation) return;

    let urlParams = {
        pid: productID,
        currentQuantity: currentQuantity,
        quantity: validation.quantity,
        uuid: uuid
    };

    url = appendToUrl(url, urlParams);

    $("body").trigger("cart:beforeUpdate");
    let productCard = $(scope).parents(".product-card");
    let errorMessageWrapper = productCard.find(".js-individual-error");

    productCard.spinner().start();

    $.ajax({
        url: url,
        type: "get",
        headers: {
            Accept: "*/*,application/json"
        },
        context: scope,
        dataType: "json",
        /**
         * Success callback function for an AJAX request
         * @param {Object} data - The response data from the AJAX request
         * @returns {void}
         */
        success: function (data) {
            productCard.spinner().stop();
            $(".quantity[data-uuid=\"" + uuid + "\"]").val(validation.quantity);
            $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
            updateCartTotals(data);
            updateItemSurcharge(data, uuid);
            updateAvailability(data, uuid);

            $(scope).data("pre-select-qty", validation.quantity);

            $("body").trigger("cart:update", data);

            if ($(scope).parents(".product-info").hasClass("bonus-product-line-item") && isCartPage) {
                location.reload();
            }
            productCard.attr("data-cart-error", "");
            $(scope).removeClass("error");
            errorMessageWrapper.addClass("d-none");
            updateAllCartPromotions(data);
            updateProductDetails({
                renderedTemplate: data.lineItemRenderedTemplate
            }, uuid);
            updateBopisAvailabilityErrorMessage(data, uuid);
            summaryHelpers.showNotApplicablePromo(data);
            validateBasket();
            summaryHelpers.updateAccordionHeight();
            initToggleShippingEvent();
            window.dispatchEvent(new CustomEvent("updateCart", {
                detail: {
                    ...data.aProduct,
                }
            }));
        },
        error: function (err) {
            productCard.spinner().stop();
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                $(".cart-error .valid-cart-error").addClass("show");
                errorMessageWrapper.find(".text").text(err.responseJSON.errorMessage);
                errorMessageWrapper.removeClass("d-none");

                var $invalidProducts = $(".product-card[data-cart-error='scroll']");

                if ($invalidProducts.length + 1 < $(".product-card").length) {
                    productCard.attr("data-cart-error", "remove");
                } else {
                    productCard.attr("data-cart-error", "scroll");
                }

                $(scope).addClass("error");
                $(".quantity[data-uuid=\"" + uuid + "\"]").val(validation.quantity);
                $(".js-cart-error").removeClass("d-none");
                $(".valid-cart-error").addClass("show");
                $(".product-card.uuid-" + uuid + "").find(".card-shipping-option.selected").removeClass("selected").addClass("disabled");
                summaryHelpers.updateAccordionHeight();
                window.dispatchEvent(new CustomEvent("errorCart", {
                    detail: {
                        type: err.responseJSON.errorMessage,
                    }
                }));
            }
            initToggleShippingEvent();
        }
    });
}

/**
 * Length and number only validation for qty input using Cleave
 */
function initQtyFormatter() {
    var Cleave = require("cleave.js").default;
    var $qtyField = $(".js-quantity");

    if ($qtyField.length) {
        $qtyField.each(function () {
            var self = $(this);
            var cleave = new Cleave(self, {
                numericOnly: true,
                blocks: [3],
                delimiters: [""]
            });

            self.data("cleave", cleave);
        });
    }
    var $qtyPdpField = $(".js-qty-input");
    if ($qtyPdpField.length) {
        $qtyPdpField.each(function () {
            var self = $(this);
            var cleave = new Cleave(self, {
                numericOnly: true,
                blocks: [3],
                delimiters: [""],
            });

            self.data("cleave", cleave);
        });
    }

    $(".js-qty-input").on("focusout", function () {
        if ($(this).val() == 0) {
            $(this).val(1);
        }
    });
}

/**
 * Returns the birthdate of a person who is 21 years old
 * @returns {Date}
 */
function getBirthdate() {
    var today = new Date();
    var birthdate = new Date(today.getFullYear() - 21, today.getMonth(), today.getDate());
    var formattedDate = utils.formatDate(birthdate);

    return formattedDate;
}

function toggleEstimatedZipCode() {
    let hasClassDNone = $(".estimate-zip-code").hasClass("collapsed");
    $(".estimate-zip-code").toggleClass("collapsed", !hasClassDNone);
    $(".estimate-zip-code-group").toggleClass("collapsed-none", !hasClassDNone);
    $(".estimate-zip-code-btn").toggleClass("collapsed-none", !hasClassDNone);
}

function updateAllCartPromotions(data) {
    if (data.promotionsDetails) {
        summaryHelpers.updatePromotionsList(data.promotionsDetails.appliedCouponsCodes);
        $(".promo-codes-wrapper").empty().append(data.promotionsDetails.appliedCouponsHTML);
        $(".rewards-codes-wrapper").empty().append(data.loyaltyRewards.rewardsHtml);
    }

    if (data.approachingDiscounts) {
        summaryHelpers.updateApproachingDiscounts(data.approachingDiscounts);
    }

    if (data.totals && data.totals.orderLevelDiscountsHtml) {
        summaryHelpers.updateOrderLevelDiscounts(data);
    }

    if (data.totals && data.totals.shippingLevelDiscountsHtml) {
        summaryHelpers.updateShippingLevelDiscounts(data);
    }
}

function initToggleShippingEvent() {
    $(".js-cart-toggle-shipping-type").on("change", function () {
        let $this = $(this);
        let selected = $this.parents(".card-shipping-option");
        let unselected = selected.parents().siblings().children(".card-shipping-option");
        let url =  $this.data("action");
        let storeId = $(".js-selected-store-id").val();
        let urlParams = {
            storeId: storeId
        };
        $this.parents(".product-card-wrapper").spinner().start();

        $this.attr("checked");
        unselected.find(".js-cart-toggle-shipping-type").removeAttr("checked");
        selected.addClass("selected");
        unselected.removeClass("selected");

        url = appendToUrl(url, urlParams);

        if (!storeId && $this.val() == "shipToStore") {
            $this.parent().find(".js-change-store-cart").trigger("click", {"radioId": $this.attr("id")});
            return false;
        }

        $("body").trigger("cart:beforeUpdate");

        $.ajax({
            url: url,
            type: "post",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            success: function (data) {
                window.dispatchEvent(new CustomEvent("shipmentUpdate", {
                    detail: {
                        productData: {
                            ...data.aShippingType
                        },
                        type: $this.val(),
                    }
                }));
                location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    window.dispatchEvent(new CustomEvent("errorCart", {
                        detail: err.responseJSON.errorMessage,
                    }));
                }
            }
        });
    });
}

/**
 * Callback function to submit zip code from cart and checkout
 * @param {Event} event - The event object
 */
function submitZipCodeInput(event) {
    event.preventDefault();
    const $zipForm = $("form.js-estimate-zip-code");
    const button = $zipForm.find("button[type='submit']");
    const url = $zipForm.attr("action");
    const $zipCodeInput = $zipForm.find("#estimatedTaxPostalCodedefault");
    const zipCode = $zipCodeInput.val();

    $.ajax({
        url: url,
        type: "get",
        headers: {
            Accept: "*/*,application/json"
        },
        data: {
            zipCode: zipCode
        },
        success: function (response) {
            if (response.success) {
                updateCartTotals(response.basket);
                toggleEstimatedZipCode();
                $zipCodeInput.val("");
            } else {
                if (response.fields != null) {
                    formValidation($zipForm, response);
                }
                updateCartTotals(response.basket);
                displayZipCodeMessage(response, button);
            }
        },
        error: function (err) {
            console.log(err);
        }
    });
}

/**
 * Clears any coupon errors on the page
 */
function clearCouponErrors() {
    $(".promo-code-form .form-control").removeClass("is-invalid");
    $(".coupon-missing-error").hide();
    $(".coupon-error-message").empty().addClass("d-none");
}

module.exports = function () {
    $(document).ready(function () {
        let rewardBarContainer = $(".rewards-bar-wrapper");
        let rewardsBar = $(".rewards-bar");
        let points = rewardsBar.data("points");
        let maxPoints = rewardsBar.data("max-points");

        if (points > maxPoints) {
            points = points - maxPoints;
        }

        rewardsBar.css("width", (rewardBarContainer.innerWidth() / maxPoints * points), "px");

        if (isCartPage) {
            rewardsHelper.manageRewardsUpdate("cart/cartCodesWrapper");
        }
    });

    $("body").on("input", ".coupon-code-field", function () {
        clearCouponErrors();
    });

    $("body").on("click", ".js-apply-cart-code", function (e) {
        e.preventDefault();
        clearCouponErrors();
        let code = $(this).attr("data-code");
        let url = $("[data-apply-url]").attr("data-apply-url")+"?couponCode="+code;
        let button = $(this);
        let errorWrapper = button.parents().closest(".code-wrapper").children(".js-error-wrapper");
        errorWrapper.removeClass("d-flex");
        errorWrapper.children(".js-error-text").empty();
        summaryHelpers.updateAccordionHeight();

        $.ajax({
            url: url,
            type: "GET",
            headers: {
                Accept: "*/*,application/json"
            },
            /**
             * Success callback function for applying a promo code
             * @param {Object} data - The response data from the server
             * @param {boolean} data.error - Indicates if there was an error applying the promo code
             * @param {boolean} data.replaced - Indicates if the promo code replaced an existing one
             * @param {string} data.totals.discountsHtml - The HTML representation of the updated discounts
             * @param {string} data.renderedHtml - The rendered HTML for the error message
             * @param {string} data.errorMessage - The error message
             * @param {string} code - The promo code that was applied
             */
            success: function (data) {
                if (data.error) {
                    if (data.replaced) {
                        $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
                        $("button.js-apply-cart-code").trigger("product:updateCartTotals", data);
                        updateAllCartPromotions(data);
                        summaryHelpers.showNotApplicablePromo(data);
                        validateBasket();

                        $(".header-banner").find("[data-coupon-code="+code+"]").text($("[data-applied-text]").data("applied-text"));

                        errorWrapper = $(`[data-code=${code}]`).parents(".code-wrapper").find(".js-error-wrapper");
                        errorWrapper.empty().append(data.renderedHtml);
                        errorWrapper.addClass("d-flex");
                    } else {
                        if (data.renderedHtml) {
                            errorWrapper.children(".js-error-text").empty().append(data.renderedHtml);
                        } else {
                            errorWrapper.children(".js-error-text").empty().append(data.errorMessage);
                        }
                        errorWrapper.addClass("d-flex");
                    }

                    window.dispatchEvent(new CustomEvent("promoError", {
                        detail: {
                            promoCode: data.couponCode,
                            type: data.errorMessage
                        }
                    }));
                } else {
                    updateAllCartPromotions(data);

                    $("button.js-apply-cart-code").trigger("product:updateCartTotals", data);
                    validateBasket();
                    $("body").trigger("promotion:success", data);
                    if ($(".cart-page").length === 0) {
                        $(".minicart-popover").addClass("promocode-notification");
                        $(".minicart .popover-open").trigger("click");
                    }

                    summaryHelpers.showNotApplicablePromo(data);

                    window.dispatchEvent(new CustomEvent("promoAdd", {
                        detail: {
                            promoCodes: [
                                {
                                    code: data.aPromo.code,
                                    name: data.aPromo.name,
                                    singleUsePromoCode: data.aPromo.singleUsePromoCode,
                                    type: "add from list",
                                }
                            ]
                        }
                    }));
                }
                $(".coupon-code-field").val("");
                if (!$(".js-promo-code-wrapper").hasClass("active")) {
                    $(".js-promo-code-wrapper").addClass("active");
                }
                summaryHelpers.updateAccordionHeight();
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                summaryHelpers.updateAccordionHeight();
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);

                    window.dispatchEvent(new CustomEvent("promoError", {
                        detail: {
                            promoCode: err.couponCode,
                            type: err.errorMessage
                        }
                    }));
                }
            }
        });
        return false;
    });

    $("form.js-estimate-zip-code").on("submit", submitZipCodeInput);

    $("form.js-estimate-zip-code input").on("keypress", function (e) {
        if (e.key === "Enter") {
            submitZipCodeInput(e);
        }
    });

    $(".js-change-zipcode").on("click", function (e) {
        toggleEstimatedZipCode();
        e.preventDefault();
        $(".estimated-zip-code-main").children(".form-group").children(".form-control").removeClass("is-invalid");
    });

    $("body").on("click", ".product-move .move", function (e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.attr("href");
        var pid = $this.data("pid");
        var $productInfo = $(this).parents(".card-product-section").find(".product-info");
        var optionId = $productInfo.find(".lineItem-options-values").data("option-id");
        var optionVal = $productInfo.find(".lineItem-options-values").data("value-id");
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            headers: {
                Accept: "*/*,application/json"
            },
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });

    initToggleShippingEvent();

    $(".optional-promo").click(function (e) {
        e.preventDefault();
        $(".promo-code-form").toggle();
    });

    $("body").on("click", ".remove-product", function (e) {
        e.preventDefault();

        var productID = $(this).data("pid");
        var url = $(this).data("action");
        var uuid = $(this).data("uuid");

        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $("body").trigger("cart:beforeUpdate");

        $.ajax({
            url: url,
            type: "get",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            /**
             * Success callback function for updating the cart
             * @param {Object} data - The response data from the server
             * @param {Object} data.basket - The updated basket object
             * @param {Array} data.toBeDeletedUUIDs - Array of UUIDs to be deleted
             * @param {boolean} data.basket.valid.error - Flag indicating if there is a validation error in the basket
             * @param {boolean} data.error - Flag indicating if there is an error
             * @param {string} data.errorMessage - Error message, if any
             * @param {boolean} data.basket.hasBonusProduct - Flag indicating if the basket has a bonus product
             * @param {string} data.basket.totals.discountsHtml - HTML string for displaying discounts in the cart
             * @param {Object} data.aProduct - Additional product data
             */
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $(".cart").remove();
                    $(".cart-header").empty().append(data.renderedTemplate);
                    $(".number-of-items").empty().append(data.basket.resources.numberOfItems);
                    $(".minicart-quantity").empty().append(data.basket.numItems);
                    $(".minicart-link").attr({
                        "aria-label": data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $(".minicart .popover").empty();
                    $(".minicart .popover").removeClass("show");
                    $("body").removeClass("modal-open");
                    $("html").removeClass("veiled");
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $(".uuid-" + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }

                    if (data && data.basket && data.basket.items) {
                        data.basket.items.forEach(function (item) {
                            updateBopisAvailabilityErrorMessage(data.basket, item.UUID);
                        });
                    }

                    if (data.basket.valid.error !== true) {
                        $(".js-cart-error").addClass("d-none");
                        $(".js-individual-error").addClass("d-none");
                    }

                    $(".uuid-" + uuid).remove();

                    $(".product-card-wrapper").each(function () {
                        if ($(this).children(".product-card").length === 0) {
                            $(this).remove();
                        }
                    });

                    if (!data.basket.hasBonusProduct) {
                        $(".bonus-product").remove();
                    }
                    $(".coupons-and-promos").empty().append(data.basket.totals.discountsHtml);
                    $("body").trigger("setShippingMethodSelection", data.basket);
                    validateBasket(data.toBeDeletedUUIDs);
                }

                if (!data.error && !data.errorMessage && data.basket) {
                    updateAllCartPromotions(data.basket);
                    updateCartTotals(data.basket);
                    summaryHelpers.showNotApplicablePromo(data.basket);
                }

                $("body").trigger("cart:update", data);
                summaryHelpers.updateAccordionHeight();
                initToggleShippingEvent();

                window.dispatchEvent(new CustomEvent("updateCart", {
                    detail: {
                        ...data.aProduct,
                    }
                }));

            },
            error: function (err) {
                summaryHelpers.updateAccordionHeight();
                initToggleShippingEvent();
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);

                    window.dispatchEvent(new CustomEvent("errorCart", {
                        detail: {
                            type: err.responseJSON.errorMessage,
                        }
                    }));
                }
            }
        });
    });

    let debounceQuantity = debounce(submitQuantityChange, 500);

    $("body").on("click", ".js-quantity-minus, .js-quantity-plus", function () {
        let siblingInput = $(this).siblings(".js-quantity");
        let trigger;
        if ($(this).hasClass("js-quantity-minus")) {
            trigger = "minus";
        } else if ($(this).hasClass("js-quantity-plus")) {
            trigger = "plus";
        }

        debounceQuantity(siblingInput[0], trigger);
        validateBasket();
        initToggleShippingEvent();
    });


    $("body").on("keyup focusout", ".js-quantity", function (e) {
        if ($(this).val() !== "") {
            debounceQuantity(this);
        } else {
            if (e.type === "focusout") {
                $(this).val($(this).data("pre-select-qty"));
            }
        }
        validateBasket();
        initToggleShippingEvent();
    });

    $(".shippingMethods").change(function () {
        var url = $(this).attr("data-actionUrl");
        var urlParams = {
            methodID: $(this).find(":selected").attr("data-shipping-id")
        };
        $(".totals").spinner().start();
        $("body").trigger("cart:beforeShippingMethodSelected");
        $.ajax({
            url: url,
            type: "post",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    summaryHelpers.updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket();
                }

                $("body").trigger("cart:shippingMethodSelected", data);

            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                }
            }
        });
    });

    $(".promo-code-form").submit(function (e) {
        e.preventDefault();
        $(".coupon-missing-error").hide();
        $(".coupon-error-message").empty();
        $(".code-wrapper .js-error-wrapper").removeClass("d-flex");
        summaryHelpers.updateAccordionHeight();

        if (!$(".coupon-code-field").val()) {
            $(".promo-code-form .form-control").addClass("is-invalid");
            $(".promo-code-form .form-control").attr("aria-describedby", "missingCouponCode");
            $(".coupon-missing-error").show();
            summaryHelpers.updateAccordionHeight();
            return false;
        }
        var $form = $(".promo-code-form");
        $(".promo-code-form .form-control").removeClass("is-invalid");
        $(".coupon-error-message").empty();
        $("body").trigger("promotion:beforeUpdate");

        $.ajax({
            url: $form.attr("action"),
            type: "GET",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    if (data.replaced) {
                        $("button.js-apply-cart-code").trigger("product:updateCartTotals", data);
                        $("[data-id="+data.lastApplied.couponCode+"]")
                            .parent(".code-wrapper")
                            .find(".js-error-wrapper")
                            .empty()
                            .append(data.renderedHtml)
                            .addClass("d-flex");
                        $(".promo-codes-wrapper").empty().append(data.promotionsDetails.appliedCouponsHTML);

                        summaryHelpers.updatePromotionsList(data.promotionsDetails.appliedCouponsCodes);

                        summaryHelpers.updateOrderLevelDiscounts(data);
                        summaryHelpers.updateShippingLevelDiscounts(data);

                        summaryHelpers.showNotApplicablePromo(data);
                        const errorWrapper = $(".promo-codes-wrapper").find(`[data-id=${data.lastApplied.couponCode}]`).parents(".code-wrapper").find(".js-error-wrapper");
                        errorWrapper.empty().append(data.renderedHtml);
                        errorWrapper.addClass("d-flex");
                    } else {
                        $(".promo-code-form .form-control").addClass("is-invalid");
                        $(".promo-code-form .form-control").attr("aria-describedby", "invalidCouponCode");
                        $(".coupon-error-message").removeClass("d-none");
                        if (data.renderedHtml) {
                            $(".coupon-error-message").empty().append(data.renderedHtml);
                        } else {
                            $(".coupon-error-message").empty().append(data.errorMessage);
                        }

                        $("body").trigger("promotion:error", data);

                        window.dispatchEvent(new CustomEvent("promoError", {
                            detail: {
                                promoCode: data.couponCode,
                                type: data.errorMessage
                            }
                        }));
                    }
                    summaryHelpers.updateAccordionHeight();
                } else {
                    $(".promo-codes-wrapper").empty().append(data.promotionsDetails.appliedCouponsHTML);

                    summaryHelpers.updatePromotionsList(data.promotionsDetails.appliedCouponsCodes);

                    summaryHelpers.updateOrderLevelDiscounts(data);
                    summaryHelpers.updateShippingLevelDiscounts(data);

                    updateCartTotals(data);
                    summaryHelpers.updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket();
                    $("body").trigger("promotion:success", data);
                    if ($(".cart-page").length === 0) {
                        $(".minicart-popover").addClass("promocode-notification");
                        $(".minicart .popover-open").trigger("click");
                    }

                    summaryHelpers.showNotApplicablePromo(data);
                    summaryHelpers.updateAccordionHeight();

                    window.dispatchEvent(new CustomEvent("promoAdd", {
                        detail: {
                            promoCodes: [
                                {
                                    code: data.aPromo.code,
                                    name: data.aPromo.name,
                                    singleUsePromoCode: data.aPromo.singleUsePromoCode,
                                    type: "manual",
                                }
                            ]
                        }
                    }));
                }
                if (!$(".js-promo-code-wrapper").hasClass("active")) {
                    $(".js-promo-code-wrapper").addClass("active");
                }
                summaryHelpers.updateAccordionHeight();
                $(".coupon-code-field").val("");
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                summaryHelpers.updateAccordionHeight();
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);

                    window.dispatchEvent(new CustomEvent("promoError", {
                        detail: {
                            promoCode: err.couponCode,
                            type: err.errorMessage
                        }
                    }));
                }
            }
        });
    });

    $("body").on("click", ".remove-coupon", function (e) {
        e.preventDefault();
        clearCouponErrors();
        let url = $(this).data("action");
        let uuid = $(this).data("uuid");
        let couponCode = $(this).data("code");
        let urlParams = {
            code: couponCode,
            uuid: uuid
        };

        let removedCoupons = $(this).data("removed-coupons");
        if (removedCoupons && removedCoupons.length > 0) {
            urlParams.couponsToApply = JSON.stringify(removedCoupons);
            urlParams.multipleCoupons = true;
        }

        let errorWrapper = $(this).parents(".promo-code").siblings(".js-error-wrapper") > 0 ? $(this).parents(".promo-code").siblings(".js-error-wrapper") : $(this).parents(".js-error-wrapper");
        errorWrapper.removeClass("d-flex");
        errorWrapper.children(".js-error-text").empty();

        url = appendToUrl(url, urlParams);

        $("body").trigger("promotion:beforeUpdate");
        $.ajax({
            url: url,
            type: "get",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            /**
             * Success callback function for a promotional code request
             * @param {Object} data - The response data from the server
             * @returns {void}
             */
            success: function (data) {
                $(".promo-codes-wrapper").empty().append(data.promotionsDetails.appliedCouponsHTML);
                $(".rewards-codes-wrapper").empty().append(data.loyaltyRewards.rewardsHtml);

                let appliedCouponsCodes = data.promotionsDetails.appliedCouponsCodes;
                $(".code-wrapper").removeClass("d-none");
                $(".code-wrapper").find(".js-error-wrapper").removeClass("d-flex");
                if (appliedCouponsCodes.length > 0) {
                    summaryHelpers.updatePromotionsList(appliedCouponsCodes);
                }

                $(".header-banner").find("[data-coupon-code="+couponCode+"]").text($("[data-apply-text]").data("apply-text"));

                if (data.promotionsDetails && data.promotionsDetails.summaryError) {
                    $(".js-promo-summary-error")
                        .empty()
                        .append(data.promotionsDetails.summaryError)
                        .addClass("pdd-16-top")
                        .show();
                } else {
                    $(".js-promo-summary-error")
                        .empty()
                        .removeClass("pdd-16-top")
                        .hide();
                }

                updateCartTotals(data);
                summaryHelpers.updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket();
                summaryHelpers.updateOrderLevelDiscounts(data);
                summaryHelpers.updateShippingLevelDiscounts(data);
                $("body").trigger("promotion:success", data);

                if ($(".minicart-wrapper").hasClass("active")) {
                    $(".js-minicart-close").click();
                }

                summaryHelpers.showNotApplicablePromo(data);
                summaryHelpers.updateAccordionHeight();

                if (data.items && data.items.length > 0) {
                    data.items.forEach(item => {
                        let hasNotApplicablePromotions = item.notApplicablePromotions && item.notApplicablePromotions.length > 0;
                        let hasBundleProductLineItems = item.bundledProductLineItems && item.bundledProductLineItems.length > 0;
                        let hasBundleNotApplicablePromotions = hasBundleProductLineItems && item.bundledProductLineItems[0].notApplicablePromotions && item.bundledProductLineItems[0].notApplicablePromotions.length > 0;

                        if (hasNotApplicablePromotions || hasBundleNotApplicablePromotions) {
                            let idClass = item.UUID;
                            let notApplicableMsg = $(".uuid-" + idClass).find(".js-not-applicable-promotions");
                            notApplicableMsg.removeClass("d-flex").addClass("d-none");
                        }
                    });
                }
                summaryHelpers.showNotApplicablePromo(data);
                summaryHelpers.updateAccordionHeight();

                window.dispatchEvent(new CustomEvent("promoRemove", {
                    detail: {
                        promoCodes: [
                            {
                                code: data.aPromo.code,
                                name: data.aPromo.name,
                                singleUsePromoCode: data.aPromo.singleUsePromoCode,
                                type: "manual",
                            }
                        ]
                    }
                }));
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);

                    window.dispatchEvent(new CustomEvent("promoError", {
                        detail: {
                            promoCode: err.couponCode,
                            type: err.errorMessage
                        }
                    }));
                }
                summaryHelpers.updateAccordionHeight();
            }
        });
    });

    $("body").on("click", ".cart-page .bonus-product-button", function () {
        $(this).addClass("launched-modal");
        $.ajax({
            url: $(this).data("url"),
            method: "GET",
            headers: {
                Accept: "*/*,application/json"
            },
            dataType: "json",
            success: function (data) {
                base.methods.editBonusProducts(data);
            },
            error: function () {
                return;
            }
        });
    });

    $("body").on("hidden.bs.modal", "#chooseBonusProductModal", function () {
        $("#chooseBonusProductModal").remove();
        $(".modal-backdrop").remove();
        $("body").removeClass("modal-open");

        if (isCartPage) {
            $(".launched-modal .btn-outline-primary").trigger("focus");
            $(".launched-modal").removeClass("launched-modal");
        } else {
            $(".product-detail .add-to-cart").focus();
        }
    });

    $("body").on("click", ".cart-page .product-edit .edit, .cart-page .bundle-edit .edit, .js-edit-wishlist-item", function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr("href");
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $("body").on("shown.bs.modal", "#editProductModal", function () {
        $("#editProductModal").siblings().attr("aria-hidden", "true");
        $("#editProductModal .close").focus();
        $("html, body").addClass("no-scroll-top");
    });

    $("body").on("hidden.bs.modal", "#editProductModal", function () {
        $("#editProductModal").siblings().attr("aria-hidden", "false");
        $("html, body").removeClass("no-scroll-top");

        //remove click event that was attached when filled modal element
        if ($(".page").data("action") === WISHLIST_DETAIL_ENDPOINT) {
            $(".js-qty-button").off("click");
        }
    });

    $("body").on("keydown", "#editProductModal", function (e) {
        var focusParams = {
            event: e,
            containerSelector: "#editProductModal",
            firstElementSelector: ".close",
            lastElementSelector: ".update-cart-product-global",
            nextToLastElementSelector: ".modal-footer .quantity-select"
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $("body").on("product:updateAddToCart", function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest(".quick-view-dialog");

        $(".update-cart-product-global", dialog).attr("disabled",
            !$(".global-availability", dialog).data("ready-to-order")
            || !$(".global-availability", dialog).data("available")
        );
    });

    $("body").on("product:updateCartTotals", function (e, response) {
        updateCartTotals(response);
    });

    $("body").on("product:updateAvailability", function (e, response) {
        // bundle individual products
        $(".product-availability", response.$productContainer)
            .data("ready-to-order", response.product.readyToOrder)
            .data("available", response.product.available)
            .find(".availability-msg")
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest(".quick-view-dialog");

        if ($(".product-availability", dialog).length) {
            // bundle all products
            var allAvailable = $(".product-availability", dialog).toArray()
                .every(function (item) { return $(item).data("available"); });

            var allReady = $(".product-availability", dialog).toArray()
                .every(function (item) { return $(item).data("ready-to-order"); });

            $(".global-availability", dialog)
                .data("ready-to-order", allReady)
                .data("available", allAvailable);

            $(".global-availability .availability-msg", dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $(".global-availability", dialog)
                .data("ready-to-order", response.product.readyToOrder)
                .data("available", response.product.available)
                .find(".availability-msg")
                .empty()
                .html(response.message);
        }
    });

    $("body").on("product:afterAttributeSelect", function (e, response) {
        if ($(".modal.show .product-quickview .bundle-items").length) {
            $(".modal.show").find(response.container).data("pid", response.data.product.id);
            $(".modal.show").find(response.container).find(".product-id").text(response.data.product.id);
        } else {
            $(".modal.show .product-quickview").data("pid", response.data.product.id);
            $(".edit-product-modal .product-id").text(response.data.product.id);
            $(".edit-product-modal .js-add-to-cart").data("addToCartProductId", response.data.product.id);
        }
    });

    $("body").on("change", ".quantity-select", function () {
        var selectedQuantity = $(this).val();
        $(".modal.show .update-cart-url").data("selected-quantity", selectedQuantity);
    });

    $("body").on("change", ".options-select", function () {
        var selectedOptionValueId = $(this).children("option:selected").data("value-id");
        $(".modal.show .update-cart-url").data("selected-option", selectedOptionValueId);
    });

    $("body").on("click", ".update-cart-product-global", function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest(".cart-and-ipay").find(".update-cart-url").val();
        var selectedQuantity = $(this).closest(".modal-footer").find(".js-qty-input").val();
        var selectedOptionValueId = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-option");
        var uuid = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("uuid");
        var editProductModal = $("#editProductModal");

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents(".card").spinner().start();

        $("body").trigger("cart:beforeUpdate");

        var $isDigitalGiftCard = $("#isDigitalGiftCard");
        var $eGiftForm  = $(".js-egift-form");
        form.isDigitalGiftCard = false;
        if ($isDigitalGiftCard.length > 0 && $isDigitalGiftCard.val() === "true") {
            $eGiftForm.serializeArray().forEach(function (field) {
                form[field.name] = field.value;
            });
            form.isDigitalGiftCard = true;
            clientSideValidation.functions.clearForm(".js-egift-form");
            clientSideValidation.functions.validateHtmlForm($eGiftForm[0]);
            if (!$eGiftForm[0].checkValidity()) {
                return;
            }
        }

        if (updateProductUrl) {
            $(".quick-view-dialog").spinner().start();
            $.ajax({
                url: updateProductUrl,
                type: "post",
                headers: {
                    Accept: "*/*,application/json"
                },
                context: this,
                data: form,
                dataType: "json",
                success: function (data) {
                    $(".quick-view-dialog").spinner().stop();
                    editProductModal.modal("hide");

                    $(".coupons-and-promos").empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    summaryHelpers.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);

                    if (data.uuidToBeDeleted) {
                        $(".uuid-" + data.uuidToBeDeleted).remove();
                    }

                    updateProductDetails(data, uuid);
                    updateBopisAvailabilityErrorMessage(data.cartModel, uuid);
                    validateBasket();

                    $("body").trigger("cart:update", data);
                    initToggleShippingEvent();
                },
                error: function (err) {
                    $(".quick-view-dialog").spinner().stop();
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        $(".quantity-error").remove();
                        editProductModal.find(".qty-incrementer, .js-quantity").addClass("error");
                        $(".prices-add-to-cart-actions").append("<div class='invalid-feedback quantity-error d-block'>" + err.responseJSON.errorMessage + "</div>");
                    }
                }
            });
        }
    });

    // Store selection: change the store
    $("body").on("click", ".js-change-store-cart", (function (e, data) {
        e.preventDefault();
        var selectedStore = $(document).find(".js-selected-store-id").val();

        var pidsObject = [];

        $(document).find(".product-card").each(function () {
            pidsObject.push($(this).attr("data-pid") + ":" + $(this).find(".js-quantity").val());
        });

        inventoryModalHelper.getModalHtmlElement(true);
        inventoryModalHelper.fillModalElement(null, null, null, null, selectedStore, pidsObject.join(","));
        var radioIdToSelect = data && data.radioId;
        if (!radioIdToSelect && !selectedStore) {
            radioIdToSelect = $(this).parents(".card-shipping-option").find(".js-cart-toggle-shipping-type").attr("ID");
        }
        if (radioIdToSelect) {
            $("#inStoreInventoryModal").find(".modal-content").data("selectStoreForUUID", radioIdToSelect);
        }
    }));

    $("body").on("click", ".checkout-btn, .btn-checkout", function (e) {
        var $invalidProduct = $(".product-card[data-cart-error='scroll']");

        if ($invalidProduct.length < $(".product-card").length) {
            $invalidProduct.attr("data-cart-error", "remove");
        }

        if ($(".product-card[data-cart-error='scroll']").length > 0 || $(".card-shipping-option").length > 0 && !$(".card-shipping-option").hasClass("selected")) {
            e.preventDefault();
            $("html").scrollTop($(".js-cart-error").offset().top - 16);
            return;
        }

        const $productsToRemove = $(".product-card[data-cart-error='remove']");
        const $productsInCart = $(".product-card");

        if ($productsToRemove.length > 0) {
            e.preventDefault();

            let ajaxCallsCompleted = 0;

            $productsToRemove.each(function () {
                $(this).find(".remove-product").trigger("click");
            });

            $(document).ajaxComplete(function () {
                ajaxCallsCompleted++;

                if (ajaxCallsCompleted === $productsToRemove.length) {
                    if ($productsInCart.length === 1) {
                        return;
                    } else {
                        window.location.href = $(".checkout-btn, .btn-checkout").attr("href");
                    }
                }
            });
        }

        let $alcoholProducts = $(".product-card[data-is-alcohol=true]");

        if ($alcoholProducts.length > 0) {
            e.preventDefault();

            $(".js-alcohol-gateway-trigger").trigger("click");
        }
    });

    $("body").on("show.bs.modal", "#cartAlcoholGateway", function (e) {
        $(e.currentTarget).find(".js-gateway-birthdate").html(getBirthdate());
    });

    $("#estimatedTaxPostalCodedefault").on("input", function () {
        $(this).removeClass("is-invalid");
        $(this).find("#estimatedTaxPostalCodedefaultError").empty();

        let zipCodeInput = $(this).val();
        // Remove all non-numeric characters
        zipCodeInput = zipCodeInput.replace(/[^0-9]/g, "");

        if (zipCodeInput.length > 5 && zipCodeInput.length < 10) {
            // Add a hyphen after the fifth digit
            zipCodeInput = zipCodeInput.replace(/(\d{5})(\d{1,4})/, "$1-$2");
            $(this).attr("minlength", 10);
        }
        // Set the zip code input value to the modified value
        $(this).val(zipCodeInput);
    });

    $(document).on("click", ".js-view-all-rewards", function (e) {
        e.preventDefault();
        if ($(".cart-page").length > 0 || $(".checkout-page").length > 0) {
            $("html, body").animate({
                scrollTop: $(".js-rewards-scroll-to").offset().top
            }, 500);
            setTimeout(() => {
                if (!$(".js-rewards-scroll-to").hasClass("active")) {
                    $(".js-rewards-open").click();
                }
            }, 600);
            $("html, body").removeClass("no-scroll-top");
        } else {
            window.location.href = $(this).data("href");
        }
    });

    $("body").on("click", ".js-reload-page", function () {
        location.reload();
    });

    // check applied coupons and hide by data attribute
    let $appliedCoupons = $("[data-applied-ids]").data("applied-ids");
    if ($appliedCoupons) summaryHelpers.updatePromotionsList($appliedCoupons);

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    initQtyFormatter();
};
